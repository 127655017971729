import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import background from '../assets/images/loader-bg.jpg';
import sokojoint from '../assets/images/loader-logo.png';
import img1 from '../assets/images/banner-happy-client.png';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import Footer from './Footer';
class Home extends Component {
    constructor(){
    super();
    this.state = {
        redirectToReferrer: true,
        adminDetail:[],
        base_url:api_url.base_url,
    };
}
componentDidMount(){
// $(window).load(function() {
    $(".loader").delay(2000).fadeOut("slow");
  $("#overlayer").delay(2000).fadeOut("slow");
// });
// $(window).load(function() {
    $(".loader").delay(2000).fadeOut("slow");
  $("#overlayer").delay(2000).fadeOut("slow");
// });



        let trackData = {
            consignee_id:1
        }
      const addUrl = this.state.base_url+'api.php?type=get_admin_number';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(trackData)
        })
        .then((response) => response.json()
            .then((result) => {
               this.setState({
                 adminDetail:result.data
               })
        }))
        .catch((error) => {

            });


}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));

// }
render(){

return(

<React.Fragment>
    <body id="page-name">

<div class="main_body register_main track_shipment_page" id="desktop_view">
<div class="login_screen">
    <div class="row top_head co-one">
      <div class="col-sm-2 back_btn">
        {//<Link to="/">
            //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
          //</Link>
        }
        </div>

      <div class="col-sm-8 page_title">
        <h4>My Shipment</h4>
      </div>
      <div class="col-sm-2 right_logo">
          <img src={sokojoint} />
        </div>
    </div>
    <div className="shipment_tabs_box">
      <ul>
        {
          localStorage.getItem('consignee_id') ?
          <li><Link to="/track-listing" className="active">To Me</Link></li>
          :
          <li><Link to="/login" className="active">To Me</Link></li>
        }


        <li><Link to="/from-me-shipment" >From Me</Link></li>
      </ul>
    </div>
    <div className="desktop_view">
      <div className="track_shipments" style={{padding: '40px 17px 16px'}}>
        <div className="form_box get_quote package_box Dutiable_box my_shipment_me" style={{marginTop: '0'}}>
          <label>If you would like to ship with Sokojoint</label>
        </div>

    </div>

     <div class="shipment_box  desktop_layout" id="rate_transit" style={{padding: '0 16px 0'}}>
      <ul>
        <li>
        <Link to="/rates" className="service_bg">
          Get Quotation Now
        </Link>
      </li>
      <li className="choose_service"><b>Or</b></li>
      <li>
          <a href={`tel:${this.state.adminDetail.phone}`} className="rate_bg">Call For Booking</a>
      </li>

      </ul>
      <div class="login_btm bottom_bar_bg">
      <img src={img1}/>
    </div>

    </div>

    </div>
  </div>

</div>
<Footer />




</body>
</React.Fragment>
    )
}
}
export default Home;
