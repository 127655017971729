import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import loginLogo from '../assets/images/loader-logo.png';
import $ from 'jquery';
import delivery from '../assets/images/delivery-100.png';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Header from './Header';
import Footer from './Footer';
class PickupRequest extends Component {
constructor(){
    super();
        this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        loading:false,
        services:[],
        to_country:'',
        from_country:'',
        weight:'',
        service:'',
        cities:[],
        pricevalue:'',
        currency:0,
    };
    this.onChange = this.onChange.bind(this);
    this.getQuotesDetails = this.getQuotesDetails.bind(this);
}
componentDidMount(){

    var api_url_city = this.state.base_url+'api.php?type=getQoutes';


     fetch(api_url_city)
    .then(res=>res.json())
    .then(result=>{

        this.setState({
            services:result.services,
            currency:result.currency,
            })
    })
    var api_ur_city = this.state.base_url+'api.php?type=getCities';


     fetch(api_ur_city)
    .then(res=>res.json())
    .then(result=>{

        this.setState({
            cities:result
        })
    })

    $("#table_btn_menu").click(function(){
        $(".main_wrapper_table").toggle();
    });
    $("#table_btn_menu2").click(function(){
        $(".main_wrapper_table2").toggle();
    });
    $("#table_btn_menu3").click(function(){
        $(".main_wrapper_table3").toggle();
    });
    $("#table_btn_menu4").click(function(){
        $(".main_wrapper_table4").toggle();
    });
}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));
// }
getQuotesDetails(e) {
    e.preventDefault();

        this.setState({loading:true})
        console.log(this.state)
        const sendData = {
            origin:this.state.to_country,
            destination:this.state.from_country,
            weight:this.state.weight,
            order_type:this.state.service,
        }
        const trackURL = this.state.base_url+'api.php?type=getQoutesDetails';
        fetch(trackURL,
        {
            method: 'POST',
            headers:
            {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body:JSON.stringify(sendData)
        })
        .then((response) => response.json())
        .then(result=>{
            console.log(result)
            this.setState({
                pricevalue:result
            })
                this.setState({loading:false});
        })
        .catch((error) => {
            this.setState({loading:false});
        });
}
onChange(e){
this.setState({[e.target.name]:e.target.value});
}
render(){
    const {loading, services, cities} = this.state;
    if (this.state.redirectToReferrer) {
        return(<Redirect to='/track-detail'
    />)
}
return(
<React.Fragment>
<div className="main_body register_main track_shipment_page" id="desktop_view">
<form onSubmit={this.getQuotesDetails}>
    <div className="login_screen" id="ratejs_page">
        <div className="row top_head co-one">
            <div className="col-sm-2 back_btn">
                {//<Link to="/">
            //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
          //</Link>
        }
        </div>
        <div className="col-sm-8 page_title">
            <h4>Rates & Transit</h4>
        </div>
         <div className="col-sm-2 right_logo">
          <img src={loginLogo} alt=""/>
        </div>
    </div>
    <div className="track_shipments desktop_view" >
        <form action="" method="">
            <div className="form_box get_quote">
                <label>Get a quote</label>
                <input type="date" placeholder="Thu 20 Aug 2020" name=""/>
            </div>

            <div className="form_box get_quote">
                <select style={{padding:'0 18px 0 35px'}} name="service" onChange={this.onChange} value={this.state.service_type} required={true} >
        <option selected="" value="">Please select service type</option>
        {services && services.map(item=>{
            return(
                <option value={item.id} key={item.service_type}>{item.service_type}</option>

                );
        })}

        </select>
        <svg className="search_box" viewBox="0 0 24 24"><path d="M5.843 9.593L11.5 15.25l5.657-5.657l-.707-.707l-4.95 4.95l-4.95-4.95l-.707.707z" fill="#626262"/></svg>

            </div>

            <div className="form_box get_quote">
                <input type="text" placeholder="Weight" name="weight" onChange={this.onChange} value={this.state.weight} required={true} />

            </div>

            <div className="form_box get_quote">
            <select style={{padding:'0 18px 0 35px'}} name="to_country" onChange={this.onChange} value={this.state.to_country} >
                <option selected="" value="">Please select pickup city</option>
                {cities && cities.map(item=>{
                    return(
                        <option value={item.city_name} key={item.city_name}>{item.city_name}</option>

                        );
                })}

                </select>

            </div>
            <div className="form_box get_quote">
            <select style={{padding:'0 18px 0 35px'}} name="from_country" onChange={this.onChange} value={this.state.from_country} >
                <option selected="" value="">Please select destination city</option>
                {cities && cities.map(item=>{
                    return(
                        <option value={item.city_name} key={item.city_name}>{item.city_name}</option>

                        );
                })}

                </select>

            </div>

        </form>
    </div>

    <div className="shipment_box rate-transits" id="rate_transit">
        <ul className="rate_shipments" id="inner_rate_box">
            <li>
                <input className="service_bg" type="submit" value="Get Rates & Transit Time" />
            </li>

        </ul>
        <div className="form_box get_quote Declared_value" style={{paddingTop:'10px'}}>
                <h5  style={{position:'absolute',left:'25px',top:'23px'}}>{this.state.currency}</h5>
                <input style={{padding:'0 18px 0 65px'}} type="text" placeholder="" name="" value={this.state.pricevalue}/>
            </div>
    </div>

</div>
</form>
</div>
<Footer />
</React.Fragment>
)
}
}
export default PickupRequest;
