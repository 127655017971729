import React, { Component } from 'react';
// import {Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom';
import background from '../assets/images/loader-bg.jpg';
import sokojoint from '../assets/images/loader-logo.png';
import img1 from '../assets/images/banner-happy-client.png';
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            redirectToReferrer: true,
        };
    }
    componentDidMount() {
            // $(window).load(function() {
            $(".loader").delay(2000).fadeOut("slow");
            $("#overlayer").delay(2000).fadeOut("slow");
            // });
            // $(window).load(function() {
            $(".loader").delay(2000).fadeOut("slow");
            $("#overlayer").delay(2000).fadeOut("slow");
            // });



        }
        // componentWillMount() {
        //  if(sessionStorage.getItem("userData")){
        //      this.getUserData();
        //  }
        //  else{
        //      this.setState({redirectToReferrer: true});
        //  }
        // }
        // getUserData() {
        //  JSON.parse(sessionStorage.getItem("userData"));

    // }
    render() {
        if (sessionStorage.getItem("consignee_id")) {
            return(<Redirect to='/track-listing'/>);
        }
        return (

            <
            React.Fragment >

            <
            div className = "main_body register_main track_shipment_page"
            id = "desktop_view" >
            <
            div className = "login_screen" >
            <
            div className = "row top_head co-one" >
            <
            div className = "col-sm-2 back_btn" >
            {//<Link to="/">
            //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
          //</Link>
        } <
            /div> <
            div className = "col-sm-8 page_title" >
            <
            h4 > My Shipment < /h4> <
            /div> <
            div className = "col-sm-2 right_logo" >
            <
            img src = { sokojoint }
            /> <
            /div> <
            /div> <
            div className = "shipment_tabs_box" >
            <
            ul >
            <
            li > <Link to="/tome-shipment"
            className = "active" > To Me </Link></li >
            < li > <Link to="/from-me-shipment"> From Me </Link></li >
            <
            /ul> <
            /div> <
            div className = "desktop_view" >
            <
            div className = "track_shipments"
            style = {
                { padding: '40px 17px 16px' } } >
            <
            div className = "form_box get_quote package_box Dutiable_box my_shipment_me"
            style = {
                { marginTop: '0' } } >
            <
            label > This feature is only available to registeered users < /label> <
            /div>

            <
            /div>

            <
            div className = "shipment_box  desktop_layout"
            id = "rate_transit"
            style = {
                { padding: '0 16px 0' } } >
            <
            ul >
            <
            li >
            <
            Link className="service_bg" to = "/signup" >
            Register Now <
            /Link> <
            /li> <
            li className = "choose_service" > < b > Or < /b></li >
            <
            li >
            <
            Link to = "/login" >
            Login Now <
            /Link> <
            /li>

            <
            /ul> <
            div className = "login_btm bottom_bar_bg" >
            <
            img src = { img1 }
            /> <
            /div>

            <
            /div>

            <
            /div> <
            /div>

            <
            /div> <
            div className = "bottom_bar" >
            <
            ul >
            <
            li id = "home_icon" >
            <
            Link to = "/" >
            <
            svg focusable = "false"
            preserveAspectRatio = "xMidYMid meet"
            viewBox = "0 0 24 24" > < path d = "M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z"
            fill = "#fff" / > < /svg> <
            b > Home < /b> <
            /Link> <
            /li> <
            li >
            <
            Link to = "/track-shipment" >

            <
            svg viewBox = "0 0 24 24" > < path d = "M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z"
            fill = "#fff" / > < /svg> <
            b > Track Shipments < /b> <
            /Link> <
            /li> <
            li >
            <
            Link to = "/rates" >
            <
            svg viewBox = "0 0 24 24" > < path d = "M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z"
            fill = "#fff" / > < /svg> <
            b > Rates & Transit < /b> <
            /Link> <
            /li> <
            /ul> <
            /div>
            <Footer /> <
            /React.Fragment>
        )
    }
}
export default Home;
