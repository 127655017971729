import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import background from '../assets/images/loader-bg.jpg';
import sokojoint from '../assets/images/sokojoint-logo.png';
import loginScreen from '../assets/images/login-screen.jpg';
import loginLogo from '../assets/images/loader-logo.png';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class Home extends Component {
    constructor(){
    super();
    this.state = {
        redirectToReferrer: true,
    };
}
componentDidMount(){
// $(window).load(function() {
    $(".loader").delay(2000).fadeOut("slow");
  $("#overlayer").delay(2000).fadeOut("slow");
// });
// $(window).load(function() {
    $(".loader").delay(2000).fadeOut("slow");
  $("#overlayer").delay(2000).fadeOut("slow");
// });


var string = "SokoJoint";
var array = string.split("");
var timer;

function frameLooper () {
  if (array.length > 0) {
    document.getElementById("text").innerHTML += array.shift();
  } else {
    clearTimeout(timer);
      }
  setTimeout(()=>frameLooper(),200);

}
frameLooper();



}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));

// }
render(){

return(

<React.Fragment>
    <div className="main_body register_main" id="desktop_view">
  <div className="offcanvas-overlay"></div>
  <span className="loader loder_black">
    <img src={background} alt="" />
    <div className="typing_text">
      <img src={sokojoint} alt="" />
      <div id="text"></div>
    </div>
  </span>

<div className="login_screen login_loader" >
    <div className="row top_head co-one"id="mian_home">
      <div className="col-sm-2 back_btn toggle_Btn">

      </div>
      <div className="col-sm-8 page_title">
        <h4>SokoJoint</h4>
      </div>
      <div className="col-sm-2 right_logo">
          <img src={loginLogo} alt=""/>
        </div>
    </div>

    <div className="shipment_tabs_box">
      <ul>
        <li><a href="to-me-shipment.html" className="active">To me</a></li><li><a href="from-me-shipment.html">From me</a></li>
      </ul>
    </div>

    <div className="shipment_box desktop_layout" id="rate-transit-box">
      <ul>
        <li>
          <Link to="/rates" className="rate_bg">
            <svg viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#fff"></path></svg>
            Rates and  transit
          </Link>
      </li>
      <li>
          <Link to="/pickup-request" className="rate-transit">
            <svg viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#fff"/></svg>
            Pickup Request
          </Link>
      </li>
      <li>
          <Link to="/track-shipment"  className="rate_bg">
            <svg viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#fff"/></svg>
            Track Shipments
          </Link>
      </li>
      {
      // <li>
      //     <a href="login.html" className="rate_bg">
      //       <svg viewBox="0 0 24 24"><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#fff"/></svg>
      //       Rider Login
      //     </a>
      // </li>
      }
      <li>
          <Link to="/contact-us"  className="service_bg">
            <svg viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#fff"/></svg>
            Contact Us
          </Link>
      </li>
      </ul>
      <div className="login_btm bottom_img_fixed">
      <img src={loginScreen} alt="" />
    </div>
    </div>

  </div>
<Footer />
</div>
</React.Fragment>
    )
}
}
export default Home;
