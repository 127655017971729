import React from 'react';
import './App.css';
import {HashRouter as Router} from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './container/Home';
import PickupRequest from './container/PickupRequest';
import TrackShippment from './container/TrackShippment';
import TrackDetails from './container/TrackDetails';
import TrackDetail from './container/TrackDetail';
import TrackingDetail from './container/TrackingDetail';
import ContactUs from './container/ContactUs';
import RatesTransit from './container/RatesTransit.js';
import TrackListing from './container/TrackListing.js';
import Login from './container/Login.js';
import Signup from './container/Signup.js';
import Toshipment from './container/Toshipment.js';
import ToshipmentDate from './container/ToshipmentDate.js';
import FromShipment from './container/FromShipment.js';
// import NoPage from './container/NoPage';
function App() {
  return (
    <React.Fragment>
      	<Router>
      	<Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/pickup-request" component={PickupRequest}/>
          <Route path="/track-shipment" component={TrackShippment}/>
          <Route path="/track-details" component={TrackDetails}/>
          <Route path="/track-detail" component={TrackDetail}/>
          <Route path="/tracking-detail/:track_no" component={TrackingDetail}/>
          <Route path="/contact-us" component={ContactUs}/>
          <Route path="/rates" component={RatesTransit}/>
          <Route path="/track-listing" component={TrackListing}/>
          <Route path="/login" component={Login}/>
          <Route path="/signup" component={Signup}/>
          <Route path="/tome-shipment" component={Toshipment}/>
          <Route path="/tome-shipment-date" component={ToshipmentDate}/>
          <Route path="/from-me-shipment" component={FromShipment}/>
		</Switch>
		</Router>
    </React.Fragment>
  );
}

export default App;
