import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import loginLogo from '../assets/images/loader-logo.png';
import $ from 'jquery';
import background from '../assets/images/770.gif';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Header from './Header';
import Footer from './Footer';
class PickupRequest extends Component {
    constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        loading:false,
        order_id:'',
        orderData:[],
        historyData:[],
        idErr:'',
    };
    this.onChange = this.onChange.bind(this);
    this.getShipmentDetails = this.getShipmentDetails.bind(this);
}
componentDidMount(){
  $("#table_btn_menu").click(function(){
    $(".main_wrapper_table").toggle();
  });
  $("#table_btn_menu2").click(function(){
    $(".main_wrapper_table2").toggle();
  });
  $("#table_btn_menu3").click(function(){
    $(".main_wrapper_table3").toggle();
  });
  $("#table_btn_menu4").click(function(){
    $(".main_wrapper_table4").toggle();
  });


}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));

// }




getShipmentDetails(e) {
    e.preventDefault();
    if (this.state.order_id==='') {
      this.setState({
        idErr:'Please enter Track No.'
      })
    }else{

      this.setState({loading:true})
      const sendData = {
          track_no:this.state.order_id
      }
      const trackURL = this.state.base_url+'api.php?type=track_shipments';
      fetch(trackURL,
      {
          method: 'POST',
          headers:
          {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body:JSON.stringify(sendData)
      })
      .then((response) => response.json())
      .then(result=>{
       if(result.response===1)
       {
           localStorage.setItem("data", JSON.stringify(result.result));
           this.setState({
               orderData:result.result,
               historyData:result.history,


           },()=>{
               this.setState({redirectToReferrer:true})
           });
       }else{
         swal('No Record Found.');
       }
       this.setState({loading:false});
      })
      .catch((error) => {
          this.setState({loading:false});
      });
    }
}

onChange(e){
   this.setState({[e.target.name]:e.target.value});
}

render(){
    const {loading} = this.state;
    if (this.state.redirectToReferrer) {
        return(<Redirect to='/track-detail'
            />)
    }
return(
<React.Fragment>



<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
      <div className="row top_head co-one">
        <div className="col-sm-2 back_btn">
          {//<Link to="/">
            //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
          //</Link>
        }
        </div>
        <div className="col-sm-8 page_title">
          <h4>Track Shipment</h4>
        </div>
        <div className="col-sm-2 right_logo">
          <img src={loginLogo} alt=""/>
        </div>
      </div>

      {loading ?
        <div className="form_loader">
                 <img src={background} alt="" />
            </div>
        :
      <form onSubmit={this.getShipmentDetails}>
      <div className="track_shipments pb-2 contact_page">
        <h4>Track Your Package Here</h4>
        <p>Track up to 10 numbers at a time. Separate by a comma (,)</p>

            <div className="form_box get_quote ">
          <input type="text" placeholder="Enter your Tracking Code" value={this.state.order_id} name="order_id" onChange={this.onChange} />
            <svg className="search_box" viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#626262"></path></svg>
        <span>{this.state.idErr}</span>
        </div>

    </div>





    <div className="shipment_box m-0 pb-3" id="rate_transit">
       <input type="submit" className="login_btn_ shipment_btns mb-0 ml-2 mt-0 mr-2 w-75" value="Track Your Package" />
      {
          //<Link to="/track-details" className="login_btn_ mb-0 ml-2 mt-0 mr-2 w-75"></Link>
      }

    </div>
    </form>
    }
  </div>
</div>


<Footer />
</React.Fragment>
    )
}
}
export default PickupRequest;
