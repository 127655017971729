import React, {Component} from 'react';
import { Link, Redirect  } from 'react-router-dom';
import loginLogo from '../assets/images/loader-logo.png';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import Footer from './Footer';
class PickupRequest extends Component {
constructor(){
super();
this.state = {
redirectToReferrer: false,
track_no:'',
base_url:api_url.base_url,
allData:[],
historyData:[],
// orderData:this.props.location.state.data,
};

this.addToListing = this.addToListing.bind(this);
this.checkLogin = this.checkLogin.bind(this);
}
componentDidUpdate()
{

}
componentDidMount(){
// localStorage.removeItem('consignee_id')
$("#table_btn_menu").click(function(){
$(".main_wrapper_table").toggle();
});
$("#table_btn_menu2").click(function(){
$(".main_wrapper_table2").toggle();
});
$("#table_btn_menu3").click(function(){
$(".main_wrapper_table3").toggle();
});
$("#table_btn_menu4").click(function(){
$(".main_wrapper_table4").toggle();
});

let trackData = {
            consignee_id:localStorage.getItem('consignee_id'),
            order_id:this.props.match.params.track_no
        }
    // console.log(trackData);
      const addUrl = this.state.base_url+'api.php?type=track_shipment';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(trackData)
        })
        .then((response) => response.json()
            .then((result) => {
                 // localStorage.setItem("data", JSON.stringify(result));
               this.setState({
                 allData:result.result,
                 historyData:result.history
               })
        }))
        .catch((error) => {

        });

}
componentWillUnmount(){
localStorage.removeItem('data');
}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));
// }
addToListing(track_no)
{
    let addData = {
        track_no:track_no,
        'consignee_id':localStorage.getItem('consignee_id')
    }
    const addUrl = this.state.base_url+'api.php?type=save_record';
    fetch(addUrl,
    {

        method: 'POST',
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(addData)
    })
    .then((response) => response.json()
        .then((result) => {
            this.setState({
                redirectToReferrer:true,
              });
    }))
    .catch((error) => {

        });
}

checkLogin(track_no)
{
    this.setState({
        track_no:track_no
    })

}
render(){
const data = this.state.allData
if(this.state.track_no!==''){
    localStorage.setItem('track_no',this.state.track_no);
    return(<Redirect to='/login' />);
}
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='/track-listing' />)
  }
return(
<React.Fragment>
<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
        <div className="row top_head co-one">
            <div className="col-sm-2 back_btn">
                {//<Link to="/">
            //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
          //</Link>
        }
        </div>
        <div className="col-sm-8 page_title">
            <h4>Track Shipment Info</h4>
        </div>
        <div className="col-sm-2 right_logo">
          <img src={loginLogo} alt=""/>
        </div>
    </div>

    <form key={this.state.allData.track_no}>
        <div className="track_details_page">
            <div className="fix_wrapper_h ">
                <div className="row main_location fix_location" id="click_btn">
                    <div className="user_name_">
                        <h3>Tracking Results {this.state.allData.track_no}

                        {localStorage.getItem('consignee_id')

                        ?

                        <button type="button" onClick={()=>this.addToListing(this.state.allData.track_no)} className="save_future">Save for Future Use</button>
                        :

                        <button onClick={()=>this.checkLogin(this.state.allData.track_no)} type="button" className="save_future">Save for Future Use</button>

                        }

                        </h3>
                    </div>
                    <div id="fix_top" className="shiping-consignee-bdr">
                        <div className="w_10_px ">
                            <ul>
                                <li><i className="fa fa-map-marker"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px track-result">
                            <h3>Shipper Information  </h3>
                            <p><b>Shipper:</b> {this.state.allData.sname} {this.state.allData.sbname && ' ( '+this.state.allData.sbname+' )'}</p>
                            <p><b>Origin:</b> {this.state.allData.origin}</p>
                        </div>
                    </div>
                    <div id="fix_top">
                        <div className="w_10_px">
                            <ul>
                                <li><i className="fa fa-user"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px track-result Consignee_box">
                            <h3>Consignee Information</h3>
                            <p><b>Name:</b> {this.state.allData.rname}</p>
                            <p><b>Destination:</b> {this.state.allData.destination}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fix_wrapper_h" id="tracking_status">
                <div className="row main_location fix_location" id="click_btn">
                    <div className="user_name_">
                        <h3>Tracking History {this.state.allData.track_no}</h3>
                    </div>
                    <div id="fix_top">
                        <div className="w_10_px">
                            <ul>
                                <li><i className="fa fa-calendar"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px status_box">
                            <h3><span>Date</span> <b>Status</b></h3>
                            {
                            this.state.historyData.map(item=>{
                            return(
                            <p key={item.id}><span>{item.created_on}   </span>  <b>{item.order_status}</b></p>
                            )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>
</div>
<Footer />
</React.Fragment>
)
}
}
export default PickupRequest;
