import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Header from './Header';
import Footer from './Footer';
class PickupRequest extends Component {
constructor(){
super();
this.state = {
base_url:api_url.base_url,
redirectToReferrer: true,
name:'',
phone_no:'',
email:'',
province:'',
pieces:'',
weight:'',
shipment_value:'',
pick_up_city:'',
shipper_address:'',
nameErr:'',
phone_noErr:'',
emailErr:'',
weightErr:'',
piecesErr:'',
cities:[],
};
this.pickUpRequest = this.pickUpRequest.bind(this);
this.onChange = this.onChange.bind(this);
}
async componentDidMount(){

    var api_url_city = this.state.base_url+'api.php?type=getCities';


    await fetch(api_url_city)
    .then(res=>res.json())
    .then(result=>{

        this.setState({
            cities:result
        })
    })


$("#table_btn_menu").click(function(){
$(".main_wrapper_table").toggle();
});
$("#table_btn_menu2").click(function(){
$(".main_wrapper_table2").toggle();
});
$("#table_btn_menu3").click(function(){
$(".main_wrapper_table3").toggle();
});
$("#table_btn_menu4").click(function(){
$(".main_wrapper_table4").toggle();
});
}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));
// }
validation(){
if(this.state.name==="" && this.state.phone_no==="" && this.state.email==="" && this.state.weight==="" && this.state.pieces==="" ){
this.setState(
{
nameErr:"Name is required",
phone_noErr:"Mobile is required",
emailErr:"Email is required",
weightErr:"Weight is required",
piecesErr:"Pieces is required",
})
}else if(this.state.name===""){
this.setState(
{
nameErr:"Name is required",
phone_noErr:"",
emailErr:"",
weightErr:"",
piecesErr:"",
})
}else if(this.state.email==="")
{
this.setState(
{
nameErr:"",
phone_noErr:"",
emailErr:"Email is required",
weightErr:"",
piecesErr:"",
})
}
else if(this.state.phone_no==="")
{
this.setState(
{
nameErr:"",
phone_noErr:"Mobile is required",
emailErr:"",
weightErr:"",
piecesErr:"",
})
}
else if(this.state.weight==="")
{
this.setState(
{
nameErr:"",
phone_noErr:"",
emailErr:"",
weightErr:"Weight is required",
piecesErr:"",
})
}
else if(this.state.pieces==="")
{
this.setState(
{
nameErr:"",
phone_noErr:"",
emailErr:"",
weightErr:"",
piecesErr:"Pieces is required",
})
}
else{
return true
}
}
pickUpRequest(e) {
e.preventDefault();
if(this.validation() ){
this.setState({
nameErr:'',
phone_noErr:'',
emailErr:'',
piecesErr:'',
weightErr:''
})
this.setState({loading:true})
const sendData = {
order_id:this.state.order_id,
name:this.state.name,
phone_no:this.state.phone_no,
email:this.state.email,
province:this.state.province,
pieces:this.state.pieces,
weight:this.state.weight,
shipment_value:this.state.shipment_value,
pick_up_city:this.state.pick_up_city,
shipper_address:this.state.shipper_address,
}
const trackURL = this.state.base_url+'api.php?type=pickup_request';
fetch(trackURL,
{
method: 'POST',
headers:
{
'Accept': 'application/json',
'Content-Type': 'application/json',
},
body:JSON.stringify(sendData)
})
.then((response) => response.json())
.then(result=>{
if(result.response===1)
{
swal('Pickup request created.');
this.setState({
order_id:'',
name:'',
phone_no:'',
email:'',
province:'',
pieces:'',
weight:'',
shipment_value:'',
pick_up_city:'',
shipper_address:'',
})
}
this.setState({loading:false});
})
.catch((error) => {
this.setState({loading:false});
});
}
}
onChange(e){
this.setState({[e.target.name]:e.target.value});
}
render(){
    const {cities} = this.state;
return(
<React.Fragment>
<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
    <Header />
        <form onSubmit={this.pickUpRequest}>

        <div className="track_shipments pb-2">
            <div className="form_box get_quote pieces_box">
                <input type="text" placeholder="Name" name="name" value={this.state.name} onChange={this.onChange} />
            <svg className="search_box" viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#626262"></path></svg>
            <span>{this.state.nameErr}</span>
        </div>
        <div className="form_box get_quote pieces_box margin_none">
            <input type="text" placeholder="Phone No" name="phone_no" value={this.state.phone_no} onChange={this.onChange}   />
            <svg className="search_box" viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#626262"/></svg>
            <span>{this.state.phone_noErr}</span>
        </div>
        <div className="form_box get_quote ">
            <input type="email" placeholder="Email" name="email" value={this.state.email} onChange={this.onChange}  />
        <svg className="search_box" viewBox="0 0 24 24"><path d="M5 5h13a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1c-.488 0-.936.175-1.283.466L11.5 11.52l7.783-5.054A1.992 1.992 0 0 0 18 6H5zm6.5 6.712L3.134 7.28A1.995 1.995 0 0 0 3 8v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V8c0-.254-.047-.497-.134-.72L11.5 12.711z" fill="#626262"></path></svg>
        <span>{this.state.emailErr}</span>
    </div>
    <div className="form_box get_quote ">
        <input type="text" placeholder="Province (Optional)" name="province" value={this.state.province} onChange={this.onChange}  />
        <svg className="search_box" viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#626262"/></svg>
    </div>
    <div className="form_box get_quote pieces_box">
        <input type="text" placeholder="Pieces" name="pieces" value={this.state.pieces} onChange={this.onChange}  />
        <svg className="search_box" viewBox="0 0 24 24"><path d="M2.7 11.02l8.667-6.772l8.934 6.98L11.633 18l-8.934-6.98zm16 .189l-7.309-5.71l-7.068 5.521l7.31 5.711l7.068-5.522zM11.634 21l-8.934-6.98l.788-.615l8.122 6.345l7.88-6.157l.812.635L11.633 21z" fill="#626262"/></svg>
        <span>{this.state.piecesErr}</span>
    </div>
    <div className="form_box get_quote pieces_box margin_none">
        <input type="text" placeholder="Weight (KG)" name="weight" value={this.state.weight}  onChange={this.onChange} />
        <svg className="search_box" viewBox="0 0 24 24"><path d="M17 7v10.5c0 3.04-2.46 5.5-5.5 5.5S6 20.54 6 17.5V6c0-2.21 1.79-4 4-4s4 1.79 4 4v10.5a2.5 2.5 0 0 1-5 0V7h1v9.5a1.5 1.5 0 0 0 3 0V6a3 3 0 0 0-6 0v11.5a4.5 4.5 0 1 0 9 0V7h1z" fill="#626262"/></svg>
        <span>{this.state.weightErr}</span>
    </div>
    <div className="form_box get_quote ">
        <input type="text" placeholder="Shipment Value (Optional)" name="shipment_value" value={this.state.shipment_value} onChange={this.onChange}  />
        <svg className="search_box" viewBox="0 0 24 24"><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#626262"/></svg>
    </div>
    <div className="form_box get_quote package_box Dutiable_box" style={{marginTop:'0px'}}>
        <select name="pick_up_city" onChange={this.onChange} value={this.state.pick_up_city} >
        <option selected="" value="">Please Select Pickup City</option>
        {cities && cities.map(item=>{
            return(
                <option value={item.city_name} key={item.city_name}>{item.city_name}</option>

                );
        })}

        </select>
    <svg className="search_box" viewBox="0 0 24 24"><path d="M5.843 9.593L11.5 15.25l5.657-5.657l-.707-.707l-4.95 4.95l-4.95-4.95l-.707.707z" fill="#212529"></path></svg>
</div>
<div className="form_box get_quote ">
    <textarea type="text" className="textarea_box" placeholder="Shipper Address Nearest Landmark (Optional)" value={this.state.shipper_address} name="shipper_address" onChange={this.onChange} ></textarea>
</div>
</div>
<div className="shipment_box m-0 pb-3" id="rate_transit">
<input type="submit"  className="login_btn_ shipment_btns mb-0 ml-2 mt-0 mr-2 w-75" value="Create Pickup Request" />
</div>
</form>
</div>
</div>
<Footer />
</React.Fragment>
)
}
}
export default PickupRequest;
