import React from 'react'
import {Link} from 'react-router-dom';
import loginLogo from '../assets/images/loader-logo.png';
const Header = () => {
    return (
        <div className="row top_head co-one">
                <div className="col-sm-2 back_btn">
                    {
                //<Link to="/">
                //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
                //</Link>
            }
            </div>
            <div className="col-sm-8 page_title">
                <h4>Pickup Request</h4>
            </div>
             <div className="col-sm-2 right_logo">
          <img src={loginLogo} alt=""/>
        </div>
        </div>
    )
}

export default Header;
